<template>
  <div class="dialog">
    <div class="dialog-header">
      
      <div class="row">
        <div class="col-8 my-auto">
          <p class="dialog-title text-white mb-0">CONTACT FORM</p>
        </div>
        <div class="col-4 my-auto">
          <img class="dialog-close-button"
               src="../../assets/images/close.png"
               alt="close-button"
               @click="$emit('close')" />
        </div>
      </div>
    </div>

    <div class="dialog-content"
         style="overflow-y: auto;"
         v-bind:style="{height: contentHeightOverride}">
      <b-form @submit="submitForm">
        <b-input-group prepend="Name*">
          <b-form-input id="name"
                        type="text"
                        autocomplete="off"
                        v-model="form.name"
                        :state="!$v.form.name.$error"
                        @input="$v.form.name.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback id="formName">
            <span v-if="!$v.form.name.required">
              Name is required.
            </span>
            <span v-if="!$v.form.name.minLength">
              Minimum length must be 2 characters.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>
        <b-input-group prepend="Title">
          <b-form-input class="is-valid"
                        id="title"
                        type="text"
                        autocomplete="off"
                        v-model="form.title"
          ></b-form-input>
        </b-input-group>
        <b-input-group prepend="School/District*">
          <b-form-input id="schoolDistrict"
                        type="text"
                        aria-describedby="schoolDistrict"
                        autocomplete="off"
                        v-model="form.schoolDistrict"
                        :state="!$v.form.schoolDistrict.$error"
                        @input="$v.form.schoolDistrict.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback id="formSchool">
            <span v-if="!$v.form.schoolDistrict.required">
              School/District is required.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>
        <b-input-group prepend="City/State*">
          <b-form-input id="cityState"
                        type="text"
                        aria-describedby="cityState"
                        autocomplete="off"
                        v-model="form.cityState"
                        :state="!$v.form.cityState.$error"
                        @input="$v.form.cityState.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback id="formCityState">
            <span v-if="!$v.form.cityState.required">
              City/State is required.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>
        <b-input-group prepend="Email*">
          <b-form-input id="email"
                        type="email"
                        aria-describedby="email"
                        autocomplete="off"
                        v-model="form.email"
                        :state="!$v.form.email.$error"
                        @input="$v.form.email.$touch()"
          ></b-form-input>
          <b-form-invalid-feedback id="formEmail">
            <span v-if="!$v.form.email.required">
              Email is required.
            </span>
            <span v-if="!$v.form.email.email">
              Please provide a valid email.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>
        <b-input-group prepend="No. of Licenses needed">
          <b-form-select :options="licenseOptions"
                         v-model="form.license"
                         size="lg"
          ></b-form-select>
        </b-input-group>

        <div class="text-center">
          <b-button class="btn btn-h3-small pl-5 pr-5"
                    variant="success"
                    type="submit"
                    :disabled="$v.form.$invalid">
            Send
          </b-button>
        </div>
      </b-form>
    </div>

    <!-- Loading -->
    <loading :active.sync="isLoading"
             :is-full-page="true"
             background-color="#000"
             color="#068bcd"
             :opacity="0.5"
             :width="70"
             :height="70">
      <rainbow-loader></rainbow-loader>
    </loading>
  </div>
</template>

<script>
  const Loading = () => import('vue-loading-overlay');
  const RainbowLoader = () => import('@/components/shared/RainbowLoader');
  
  import BreakpointMixin from '@/mixins/BreakpointMixin';
  import { required, email, minLength } from 'vuelidate/lib/validators';
  import 'vue-loading-overlay/dist/vue-loading.css';
  

  export default {
    mixins : [
      BreakpointMixin,
    ],
    data() {
      return {
        isLoading : false,
        form      : {
          name           : null,
          title          : null,
          schoolDistrict : null,
          cityState      : null,
          email          : null,
          license        : null,
        },
        licenseOptions : [
          { value : null, text : 'Please select' },
          { value : '<250', text : '<250' },
          { value : '250-500', text : '250-500' },
          { value : '500+', text : '500+' },
        ],
      }
    },
    components : {
      Loading,
      RainbowLoader,
    },
    methods : {
      submitForm : function(e) {
        e.preventDefault();

        this.isLoading = true;
        this.$http.post('auth/subscriptionContactEmail', this.form)
          .then(() => {
            this.$emit('close');
            this.$notify({
              group : 'notif',
              type  : 'success',
              title : 'Success',
              text  : 'Contact form submitted successfully.',
            });
          }).catch(() => {
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }).finally(() => {
            this.isLoading = false;
            this.$analytics.fbq.event('Lead');
          });
      },
    },
    validations() {
      return {
        form : {
          name           : { required, minLength : minLength(2) },
          title          : {},
          schoolDistrict : { required },
          cityState      : { required },
          email          : { required, email },
          license        : {},
        },
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/modals/subscription-contact-form";
</style>